import React from 'react';
import './App.css';
import LoginPage from "./Components/LoginPage/LoginPage";

function App() {
  return (

      <LoginPage />
  );
}

export default App;
