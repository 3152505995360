import React from "react";
import "./LoginPage.scss";

const LoginHeader: React.FC = () => {
    return (
        <div className="login-header">
            <button className="back-button">←</button>
            <div className="welcome-text">
                <h1>Добро</h1>
                <h2>Пожаловать</h2>
            </div>
        </div>
    );
};

export default LoginHeader;
