import axios from "../axios/axios";



const apiService = {
    login: async (phoneNumber: string, password: string) => {
        const response = await axios.post("/api/auth/login", { phoneNumber, password });
        return response.data.token;
    },
};

export default apiService;
