import React, { useState } from "react";
import Input from "../UI/Input/Input";
import Button from "../UI/Button/Button";
import apiService from "../../services/apiService";
import "./LoginPage.scss";
import ErrorMessage from "../UI/ErrorMessageProps/ErrorMessageProps";

const LoginForm: React.FC = () => {
    const [phoneNumber, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState<string>("");

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const token = await apiService.login(phoneNumber, password);
            localStorage.setItem("authToken", token);
            alert("Вы успешно вошли!");
            setError("");
        } catch (error: any) {
            if (error.response && error.response.data) {
                const errorMessage = error.response.data
                    ? error.response.data
                    : "Ошибка входа";
                setError(errorMessage);
            } else {
                setError("Ошибка входа");
            }
        }
    };

    return (
        <form className="login-form" onSubmit={handleSubmit}>
            <Input
                placeholder="Номер телефона 77051122333"
                type="text"
                onChange={(e) => setPhone(e.target.value)}
            />
            <Input
                placeholder="Пароль"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
            />
            {error && <ErrorMessage message={error} />}
            <Button text="ВОЙТИ" variant="primary" />
            <a href="/forgot-password" className="forgot-password">Забыли пароль?</a>
            <div className="register-wrapper">
                <span>Нет аккаунта?</span>
                <a href="/register" className="register-link">Создать</a>
            </div>
        </form>
    );
};

export default LoginForm;
