import React from "react";
import "./Input.scss";

interface InputProps {
    placeholder: string;
    type?: "text" | "password";
    icon?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input: React.FC<InputProps> = ({ placeholder, type = "text", icon, onChange }) => {
    return (
        <div className="input-wrapper">
            {icon && <span className="input-icon">{icon}</span>}
            <input
                className="input-field"
                type={type}
                placeholder={placeholder}
                onChange={onChange}
            />
        </div>
    );
};

export default Input;
